var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-article"},[_c('Navbar'),(_vm.articles.data.length > 0)?_c('section',{staticClass:"old-post mb-5",attrs:{"id":"old-post"}},[_c('div',{staticClass:"container post-wrap pt-3"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-md-12 hero-title"},[_c('span',{staticClass:"rounded-pill"}),_c('h3',[_vm._v("Article "+_vm._s(_vm.articles.data[0].categori.name))])])]),(_vm.articles.data.length > 0)?_c('div',{staticClass:"row mt-4"},_vm._l((_vm.articles.data),function(article){return _c('div',{key:article.id,staticClass:"col-md-6 col-lg-6 col-xl-4 my-3"},[_c('div',{staticClass:"card"},[_c('router-link',{staticClass:"text-decoration-none",attrs:{"to":{
                name: 'ArticleDetail',
                params: { slug: article.slug },
              }}},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(
                  ("https://dashboard.infoin.auroraweb.id/storage/" + (article.thumbnail))
                ),expression:"\n                  `https://dashboard.infoin.auroraweb.id/storage/${article.thumbnail}`\n                "}],staticClass:"card-img-top",attrs:{"alt":article.title,"height":"250px"}})]),_c('div',{staticClass:"card-body d-flex flex-column justify-content-between"},[_c('router-link',{staticClass:"text-decoration-none",attrs:{"to":{
                  name: 'ArticleDetail',
                  params: { slug: article.slug },
                }}},[(article.title.length > 50)?_c('h5',{staticClass:"card-title",staticStyle:{"font-family":"'Poppins', sans-serif","font-weight":"500"}},[_vm._v(" "+_vm._s(_vm.strippedContent(article.title, 50))+".. ")]):_c('h5',{staticClass:"card-title",staticStyle:{"font-family":"'Poppins', sans-serif","font-weight":"500"}},[_vm._v(" "+_vm._s(_vm.strippedContent(article.title, 50))+" ")])]),_c('div',{staticClass:"author d-flex mt-4 align-items-center"},[_c('router-link',{staticClass:"author-img",attrs:{"to":{
                    name: 'Profile',
                    params: { slug: article.user.slug },
                  }}},[(article.user.google_id)?_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(article.user.avatar),expression:"article.user.avatar"}],staticClass:"rounded-circle",attrs:{"alt":"author","width":"50","height":"50"}}):_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(
                      ("https://dashboard.infoin.auroraweb.id/storage/" + (article.user.avatar))
                    ),expression:"\n                      `https://dashboard.infoin.auroraweb.id/storage/${article.user.avatar}`\n                    "}],staticClass:"rounded-circle",attrs:{"alt":"author","width":"50","height":"50"}})]),_c('div',{staticClass:"author-name ml-3"},[_c('p',{staticClass:"m-0"},[_c('router-link',{attrs:{"to":{
                        name: 'Profile',
                        params: { slug: article.user.slug },
                      }}},[_vm._v(_vm._s(article.user.name))]),_vm._v(" in "),_c('router-link',{attrs:{"to":{
                        name: 'Category',
                        params: { slug: article.categori.slug },
                      }}},[_vm._v(_vm._s(article.categori.name))])],1),_c('p',{staticClass:"m-0"},[_vm._v(" "+_vm._s(_vm.setDate(article.created_at, "LL"))+" • "+_vm._s(article.min_read)+" min read ")])])],1)],1)],1)])}),0):_c('div',{staticClass:"row"},_vm._l((3),function(n){return _c('div',{key:n,staticClass:"col-md-6 col-lg-6 col-xl-4 my-3"},[_c('b-col',[_c('b-skeleton-img')],1),_c('b-col',[_c('b-skeleton',{staticClass:"mt-3",attrs:{"animation":"wave","width":"85%"}}),_c('b-skeleton',{attrs:{"animation":"wave","width":"55%"}}),_c('b-skeleton',{attrs:{"animation":"wave","width":"70%"}})],1)],1)}),0),(_vm.articles.data.length > 0)?_c('div',{staticClass:"row justify-content-center"},[_c('pagination',{attrs:{"data":_vm.articles,"align":'center'},on:{"pagination-change-page":_vm.getArticles}})],1):_vm._e()])]):_vm._e(),(_vm.donations.data.length > 0)?_c('div',{staticClass:"donations mt-3"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 hero-title"},[_c('span',{staticClass:"rounded-pill"}),_c('h3',[_vm._v("Donation "+_vm._s(_vm.donations.data[0].categories[0].name))])])]),(_vm.donations.data.length > 0)?_c('div',{staticClass:"row"},_vm._l((_vm.donations.data),function(donation){return _c('div',{key:donation.id,staticClass:"col-md-6 my-3"},[_c('div',{staticClass:"card border-0 p-3"},[_c('router-link',{attrs:{"to":{
                name: 'DonationDetail',
                params: { slug: donation.slug },
              }}},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(
                  ("https://dashboard.infoin.auroraweb.id/storage/" + (donation.thumbnail))
                ),expression:"\n                  `https://dashboard.infoin.auroraweb.id/storage/${donation.thumbnail}`\n                "}],staticClass:"card-img-top img-fluid",attrs:{"alt":donation.title}})]),_c('div',{staticClass:"card-body p-0 mt-3"},[_c('div',{staticClass:"donasi-item"},[_c('div',{staticClass:"main-donasi"},[_c('div',{staticClass:"row justify-content-between align-items-center mb-3"},[_c('div',{staticClass:"col-lg-7 my-2"},[_c('div',{staticClass:"tag-donasi"},_vm._l((donation.categories),function(category){return _c('router-link',{key:category.id,staticClass:"rounded-pill text-decoration-none mx-1",attrs:{"to":{
                            name: 'Category',
                            params: { slug: category.slug },
                          }}},[_vm._v(_vm._s(category.name))])}),1)]),_c('div',{staticClass:"col-lg d-flex justify-content-lg-end"},[_c('div',{directives:[{name:"lazy-container",rawName:"v-lazy-container",value:({ selector: 'img' }),expression:"{ selector: 'img' }"}],staticClass:"time-donasi d-flex align-items-center ml-2 mt-2 ml-lg-0 mt-lg-0"},[_c('img',{staticClass:"mr-2",attrs:{"data-src":"/assets/icons/time.svg","alt":"time"}}),(donation.timeLeft > 0)?_c('countdown',{attrs:{"time":donation.timeLeft},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_vm._v(_vm._s(props.days)+" Days Left ")]}}],null,true)}):_c('span',[_vm._v("Hari Ini")])],1)])]),_c('router-link',{staticClass:"text-decoration-none",attrs:{"to":{
                      name: 'DonationDetail',
                      params: { slug: donation.slug },
                    }}},[_c('h5',[_vm._v(" "+_vm._s(donation.title)+" ")])]),_c('b-progress',{staticClass:"mb-3",attrs:{"value":_vm.getDanaTerkumpul(donation.donation_details),"max":donation.max_dana}}),_c('div',{staticClass:"d-flex justify-content-between mt-2 mb-4"},[_c('span',[_vm._v("Target : Rp "+_vm._s(_vm.formatPrice(donation.max_dana)))]),(
                        donation.max_dana >
                        _vm.getDanaTerkumpul(donation.donation_details)
                      )?_c('span',{staticClass:"text-right"},[_vm._v(" Remaining : Rp "+_vm._s(_vm.formatPrice( donation.max_dana - _vm.getDanaTerkumpul(donation.donation_details) )))]):_c('span',{staticClass:"text-right"},[_vm._v(" Remaining : Terpenuhi ")])]),_c('p',[_vm._v(_vm._s(_vm.strippedContent(donation.content, 150))+"..")]),_c('hr'),_c('router-link',{staticClass:"btn btn-main btn-block",staticStyle:{"border-radius":"25px"},attrs:{"to":{
                      name: 'DonationDetail',
                      params: { slug: donation.slug },
                    }}},[_vm._v(" DONASI SEKARANG ")])],1)])])],1)])}),0):_c('div',{staticClass:"row justify-content-between"},[_c('div',{staticClass:"col-md-6 my-3"},[_c('div',{staticClass:"card border-0 p-3"},[_c('b-skeleton-img'),_c('div',{staticClass:"card-body p-0 mt-3"},[_c('div',{staticClass:"donasi-item"},[_c('div',{staticClass:"main-donasi"},[_c('b-skeleton',{attrs:{"animation":"wave","width":"85%"}}),_c('b-skeleton',{attrs:{"animation":"wave","width":"55%"}}),_c('b-skeleton',{attrs:{"animation":"wave","width":"70%"}})],1),_c('div',[_c('hr'),_c('b-skeleton',{staticClass:"btn-block",attrs:{"type":"button"}})],1)])])],1)])]),(_vm.donations.data.length > 0)?_c('div',{staticClass:"row justify-content-center"},[_c('pagination',{attrs:{"data":_vm.donations,"align":'center'},on:{"pagination-change-page":_vm.getDonations}})],1):_vm._e()])]):_vm._e(),_c('Footer')],1)}
var staticRenderFns = []

export { render, staticRenderFns }